import Ranking from "../types/Ranking";
import OverallStandings from "../types/OverallStandings";
import OwnContribution from "../types/OwnContribution";
import * as config from '../config';

const baseUrl = 'https://us-central1-mysper-e5462.cloudfunctions.net';

export async function getOverallStandings(locale: string): Promise<any> {
    let url = baseUrl + '/getOverallStandings?competitionId=' + config.COMPETITION_ID;

    if (locale) {
        url += '&locale=' + locale;
    }

    const requestOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        redirect: 'follow'
    };

    try {
        const response = await fetch(url, requestOptions);
        const json = await response.json();
        return json as { overallStandings: OverallStandings };
    } catch (e) {
        console.log(e);
    }
    return null;
}

export async function getOwnContribution(userId: string, teamId: string, teamIds: string): Promise<any> {
    if (!userId) {
        return null;
    }

    let url = baseUrl + '/getOwnContribution?competitionId=' + config.COMPETITION_ID + '&userId=' + userId;

    if (teamIds) {
        url += `&teamIds=${teamIds}`;
    } else if (teamId) {
        url += `&teamId=${teamId}`;
    }

    const requestOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        redirect: 'follow'
    };

    try {
        const response = await fetch(url, requestOptions);
        const json = await response.json();
        return json as { ownContribution: OwnContribution };
    } catch (e) {
        console.log(e);
    }
    return null;
}

export async function getRegionsRanking(teamId?: string | null, teamIds?: string | null): Promise<Ranking> {
    return await getRanking("divisions", teamId, teamIds)
}

export async function getCountriesRanking(teamId?: string | null, teamIds?: string | null): Promise<Ranking> {
    return await getRanking("subdivisions", teamId, teamIds)
}

export async function getTeamsRanking(teamId?: string | null, teamIds?: string | null): Promise<Ranking> {
    return await getRanking("teams", teamId, teamIds)
}

export async function getRanking(type: string, teamId?: string | null, teamIds?: string | null): Promise<Ranking> {
    let url = baseUrl + '/getRanking?competitionId=' + config.COMPETITION_ID + '&type=' + type;

    if (teamIds) {
        url += `&teamIds=${teamIds}`;
    } else if (teamId) {
        url += `&teamId=${teamId}`;
    }

    const requestOptions: RequestInit = {
        method: 'GET',
        mode: 'cors',
        redirect: 'follow'
    };

    try {
        const response = await fetch(url, requestOptions);
        const json = await response.json();
        return json as Ranking;
    } catch (e) {
        console.log(e);
    }
    return {
        monthly: [],
        quarterly: [],
        highlighted: [],
    };
}
