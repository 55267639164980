import styled from "styled-components";
import "../i18n";
import React, { useState, useEffect, useCallback } from "react";
import Icon from "@mdi/react";
import { mdiChartLine, mdiAccountMultiple } from "@mdi/js";
import {
  buildStyles,
  CircularProgressbarWithChildren,
} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import i18next, { t, changeLanguage } from "i18next";
import OverallStandings from "../types/OverallStandings";
import * as GoalsRestClient from "../services/GoalsRestClient";
import OwnContribution from "../types/OwnContribution";
import Ranking from "../types/Ranking";
import RankingEntry from "../types/RankingEntry";
import * as SharedConstants from "../constants/SharedConstants";
import { useSearchParams } from "react-router-dom";
import logo from "../assets/img/schenkerlogo.png";

export const SCHENKER_GREEN = "#167B83";

const countriesMapping = require("../data/countriesMapping.json");
const countryHeadcounts = require("../data/countryHeadcounts.json");

interface MicrositeProps {}

const Microsite = (props: MicrositeProps) => {
  let [searchParams] = useSearchParams();

  const [teamId, setTeamId] = useState("");
  const [teamIds, setTeamIds] = useState("");
  const [userId, setUserId] = useState("");

  const [overallStandings, setOverallStandings] = useState<OverallStandings>();
  const [ownContribution, setOwnContribution] = useState<OwnContribution>();
  const [quarterTimePassed, setQuarterTimePassed] = useState<number>(0);

  const [regionsRankingListItemsMonthly, setRegionsRankingListItemsMonthly] =
    useState<Array<object>>([]);
  const [
    regionsRankingListItemsQuarterly,
    setRegionsRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [regionsRankingPeriod, setRegionsRankingPeriod] = useState(
    SharedConstants.MONTHLY
  );
  const [
    regionsAfterTenRankingListItemsMonthly,
    setRegionsAfterTenRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    regionsAfterTenRankingListItemsQuarterly,
    setRegionsAfterTenRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [showAllRegions, setShowAllRegions] = useState(false);

  const [
    smallCountriesRankingListItemsMonthly,
    setSmallCountriesRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    smallCountriesRankingListItemsQuarterly,
    setSmallCountriesRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [
    mediumCountriesRankingListItemsMonthly,
    setMediumCountriesRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    mediumCountriesRankingListItemsQuarterly,
    setMediumCountriesRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [
    largeCountriesRankingListItemsMonthly,
    setLargeCountriesRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    largeCountriesRankingListItemsQuarterly,
    setLargeCountriesRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [smallCountriesRankingPeriod, setSmallCountriesRankingPeriod] = useState(
    SharedConstants.MONTHLY
  );
  const [mediumCountriesRankingPeriod, setMediumCountriesRankingPeriod] = useState(
    SharedConstants.MONTHLY
  );
  const [largeCountriesRankingPeriod, setLargeCountriesRankingPeriod] = useState(
    SharedConstants.MONTHLY
  );
  const [
    smallCountriesAfterTenRankingListItemsMonthly,
    setSmallCountriesAfterTenRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    smallCountriesAfterTenRankingListItemsQuarterly,
    setSmallCountriesAfterTenRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [
    mediumCountriesAfterTenRankingListItemsMonthly,
    setMediumCountriesAfterTenRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    mediumCountriesAfterTenRankingListItemsQuarterly,
    setMediumCountriesAfterTenRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [
    largeCountriesAfterTenRankingListItemsMonthly,
    setLargeCountriesAfterTenRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    largeCountriesAfterTenRankingListItemsQuarterly,
    setLargeCountriesAfterTenRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [showAllSmallCountries, setShowAllSmallCountries] = useState(false);
  const [showAllMediumCountries, setShowAllMediumCountries] = useState(false);
  const [showAllLargeCountries, setShowAllLargeCountries] = useState(false);

  const [teamsRankingListItemsMonthly, setTeamsRankingListItemsMonthly] =
    useState<Array<object>>([]);
  const [teamsRankingListItemsQuarterly, setTeamsRankingListItemsQuarterly] =
    useState<Array<object>>([]);
  const [teamsRankingPeriod, setTeamsRankingPeriod] = useState(
    SharedConstants.MONTHLY
  );
  const [
    teamsAfterTenRankingListItemsMonthly,
    setTeamsAfterTenRankingListItemsMonthly,
  ] = useState<Array<object>>([]);
  const [
    teamsAfterTenRankingListItemsQuarterly,
    setTeamsAfterTenRankingListItemsQuarterly,
  ] = useState<Array<object>>([]);
  const [ownTeamListItemMonthly, setOwnTeamListItemMonthly] =
    useState<object>();
  const [ownTeamListItemQuarterly, setOwnTeamListItemQuarterly] =
    useState<object>();
  const [showAllTeams, setShowAllTeams] = useState(false);

  const loadOverallStandings = useCallback(async () => {
    let overallStandings: OverallStandings =
      await GoalsRestClient.getOverallStandings(i18next.language);
    setOverallStandings(overallStandings);
    loadQuarterTimePassed(overallStandings);
  }, []);

  const loadOwnContribution = useCallback(async () => {
    let ownContribution: OwnContribution =
      await GoalsRestClient.getOwnContribution(userId, teamId, teamIds);
    setOwnContribution(ownContribution);
  }, [userId, teamId, teamIds]);

  const loadQuarterTimePassed = (overallStandings: OverallStandings) => {
    let today = new Date();

    if (
      !overallStandings?.startTime ||
      today.getTime() < overallStandings.startTime
    ) {
      setQuarterTimePassed(0);
      return;
    }

    let quarter = Math.floor((today.getMonth() + 3) / 3);
    let startingMonth =
      quarter === 4 ? 9 : quarter === 3 ? 6 : quarter === 2 ? 3 : 0;
    let quarterStartDate = new Date(
      today.getFullYear(),
      startingMonth,
      1,
      0
    ).getTime();
    let quarterEndDate =
      new Date(today.getFullYear(), startingMonth + 3, 1, 0).getTime() - 1000;
    let quarterLength = quarterEndDate - quarterStartDate;
    let timeSinceQuarterStartDate = today.getTime() - quarterStartDate;
    let quarterTimePassed = timeSinceQuarterStartDate / quarterLength;
    let quarterTimePassedPercent = Math.round(quarterTimePassed * 100);
    setQuarterTimePassed(quarterTimePassedPercent);
  };

  const setRankingList = useCallback(
    (
      sourceArray: Array<RankingEntry>,
      setFunction: (
        value: ((prevState: Array<object>) => Array<object>) | Array<object>
      ) => void,
      highlightIds: string[],
      metric: string,
      scoreWidth: number
    ): void => {
      const itemsList = [];
      for (let i = 0; i < sourceArray.length; i++) {
        const isHighlighted =
          highlightIds && highlightIds.includes(sourceArray[i].id);
        itemsList.push(
          <RankingListItem
            key={"rankingListItem" + sourceArray[i].id}
            backgroundColor={isHighlighted ? "#BBBBBB" : "#FFFFFF"}
          >
            <TextContainer width={10}>
              <Text
                fontSize={14}
                fontColor={"#000000"}
                fontWeight={isHighlighted ? "bold" : "normal"}
              >
                {sourceArray[i].position}.
              </Text>
            </TextContainer>
            <TextContainer width={85 - scoreWidth} textAlign={"left"}>
              <Text
                textAlign={"left"}
                fontSize={14}
                fontColor={"#000000"}
                fontWeight={isHighlighted ? "bold" : "normal"}
              >
                {sourceArray[i].name}
              </Text>
            </TextContainer>
            <TextContainer width={scoreWidth} textAlign={"right"}>
              <Text
                fontSize={14}
                fontColor={"#000000"}
                fontWeight={isHighlighted ? "bold" : "normal"}
                textAlign={"right"}
                paddingRight={10}
              >
                {Number(sourceArray[i].score).toFixed(2)} {metric}
              </Text>
            </TextContainer>
          </RankingListItem>
        );
        if (i < sourceArray.length) {
          itemsList.push(
            <HorizontalRule key={"horizontalRule" + sourceArray[i].id} />
          );
        }
      }
      setFunction(itemsList);
    },
    []
  );

  const getSizeOfCountryCategory = (country: string) => {
    const countryHeadcount = countryHeadcounts[countriesMapping[country]];
    if (countryHeadcount < 500) {
      return 1;
    }
    if (countryHeadcount > 1499) {
      return 3;
    }
    return 2;
  };

  const loadRankingListItems = useCallback(
    async (teamId: string | null, teamIds: string | null) => {
      let regionsRanking: Ranking = await GoalsRestClient.getRegionsRanking(
        teamId,
        teamIds
      );
      setRankingList(
        regionsRanking.monthly.slice(0, 10),
        setRegionsRankingListItemsMonthly,
        regionsRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        regionsRanking.quarterly.slice(0, 10),
        setRegionsRankingListItemsQuarterly,
        regionsRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        regionsRanking.monthly.slice(10),
        setRegionsAfterTenRankingListItemsMonthly,
        regionsRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        regionsRanking.quarterly.slice(10),
        setRegionsAfterTenRankingListItemsQuarterly,
        regionsRanking.highlighted,
        "%",
        20
      );
      let countriesRanking: Ranking = await GoalsRestClient.getCountriesRanking(
        teamId,
        teamIds
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 1)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(0, 10),
        setSmallCountriesRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 2)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(0, 10),
        setMediumCountriesRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 3)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(0, 10),
        setLargeCountriesRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.quarterly
          .filter((e) => getSizeOfCountryCategory(e.id) === 1)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(0, 10),
        setSmallCountriesRankingListItemsQuarterly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.quarterly
          .filter((e) => getSizeOfCountryCategory(e.id) === 2)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(0, 10),
        setMediumCountriesRankingListItemsQuarterly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.quarterly
          .filter((e) => getSizeOfCountryCategory(e.id) === 3)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(0, 10),
        setLargeCountriesRankingListItemsQuarterly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 1)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(10),
        setSmallCountriesAfterTenRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 2)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(10),
        setMediumCountriesAfterTenRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.monthly
          .filter((e) => getSizeOfCountryCategory(e.id) === 3)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(10),
        setLargeCountriesAfterTenRankingListItemsMonthly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.quarterly
          .filter((e) => getSizeOfCountryCategory(e.id) === 1)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(10),
        setSmallCountriesAfterTenRankingListItemsQuarterly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.quarterly
          .filter((e) => getSizeOfCountryCategory(e.id) === 2)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(10),
        setMediumCountriesAfterTenRankingListItemsQuarterly,
        countriesRanking.highlighted,
        "%",
        20
      );
      setRankingList(
        countriesRanking.quarterly
          .filter((e) => getSizeOfCountryCategory(e.id) === 3)
          .map((e, i) => {
            e.position = i + 1;
            return e;
          })
          .slice(10),
        setLargeCountriesAfterTenRankingListItemsQuarterly,
        countriesRanking.highlighted,
        "%",
        20
      );
      let teamsRanking: Ranking = await GoalsRestClient.getTeamsRanking(
        teamId,
        teamIds
      );
      setRankingList(
        teamsRanking.monthly.slice(0, 10),
        setTeamsRankingListItemsMonthly,
        teamsRanking.highlighted,
        "km / person",
        45
      );
      setRankingList(
        teamsRanking.quarterly.slice(0, 10),
        setTeamsRankingListItemsQuarterly,
        teamsRanking.highlighted,
        "km / person",
        45
      );
      setRankingList(
        teamsRanking.monthly.slice(10),
        setTeamsAfterTenRankingListItemsMonthly,
        teamsRanking.highlighted,
        "km / person",
        45
      );
      setRankingList(
        teamsRanking.quarterly.slice(10),
        setTeamsAfterTenRankingListItemsQuarterly,
        teamsRanking.highlighted,
        "km / person",
        45
      );
      var ownTeamMonthly = teamsRanking.monthly.filter((team) => {
        return (
          (team.id === teamId || teamIds?.includes(team.id)) &&
          team.position > 10
        );
      });
      if (
        ownTeamMonthly &&
        ownTeamMonthly[0] &&
        ownTeamMonthly[0].position > 10
      ) {
        setRankingList(
          ownTeamMonthly,
          setOwnTeamListItemMonthly,
          teamsRanking.highlighted,
          "km / person",
          45
        );
      }
      var ownTeamQuarterly = teamsRanking.quarterly.filter((team) => {
        return (
          (team.id === teamId || teamIds?.includes(team.id)) &&
          team.position > 10
        );
      });
      if (
        ownTeamQuarterly &&
        ownTeamQuarterly[0] &&
        ownTeamQuarterly[0].position > 10
      ) {
        setRankingList(
          ownTeamQuarterly,
          setOwnTeamListItemQuarterly,
          teamsRanking.highlighted,
          "km / person",
          45
        );
      }
    },
    [
      setRankingList,
      setOwnTeamListItemMonthly,
      setOwnTeamListItemQuarterly,
      setRegionsRankingListItemsMonthly,
      setRegionsRankingListItemsQuarterly,
      setRegionsAfterTenRankingListItemsMonthly,
      setRegionsAfterTenRankingListItemsQuarterly,
      setSmallCountriesRankingListItemsMonthly,
      setSmallCountriesRankingListItemsQuarterly,
      setMediumCountriesRankingListItemsMonthly,
      setMediumCountriesRankingListItemsQuarterly,
      setLargeCountriesRankingListItemsMonthly,
      setLargeCountriesRankingListItemsQuarterly,
      setSmallCountriesAfterTenRankingListItemsMonthly,
      setSmallCountriesAfterTenRankingListItemsQuarterly,
      setMediumCountriesAfterTenRankingListItemsMonthly,
      setMediumCountriesAfterTenRankingListItemsQuarterly,
      setLargeCountriesAfterTenRankingListItemsMonthly,
      setLargeCountriesAfterTenRankingListItemsQuarterly,
      setTeamsRankingListItemsMonthly,
      setTeamsRankingListItemsQuarterly,
      setTeamsAfterTenRankingListItemsMonthly,
      setTeamsAfterTenRankingListItemsQuarterly,
    ]
  );

  useEffect(() => {
    let teamId = searchParams.get("teamId");
    setTeamId(teamId ? teamId : "");
    const teamIds = searchParams.get("teamIds");
    if (typeof teamIds === "string" && teamIds !== "{teamIds}") {
      setTeamIds(teamIds);
    }
    let userId = searchParams.get("userId");
    setUserId(userId ? userId : "");
    let locale = searchParams.get("locale") || navigator.language || "en";
    changeLanguage(locale.substring(0, 2));
    loadRankingListItems(teamId, teamIds);
  }, [searchParams, loadRankingListItems]);

  useEffect(() => {
    loadOverallStandings();
  }, [loadOverallStandings]);

  useEffect(() => {
    loadOwnContribution();
  }, [loadOwnContribution]);

  return (
    <Container>
      <BackgroundHeader id={"nav"} gridArea={"nav"} />
      <VerticalGridContainer style={{ marginTop: -70 }}>
        <CardContainer>
          <CardContainerHeader>
            <CardContainerHeaderProgress
              width={
                overallStandings?.longGoal?.current &&
                overallStandings?.longGoal?.goal
                  ? (overallStandings?.longGoal?.current /
                      overallStandings.longGoal.goal) *
                    100
                  : 0
              }
            />
            <Text
              align={"left"}
              fontSize={10}
              fontWeight={"bold"}
              fontColor={"#000000"}
            >
              {t("OVERALL_GOAL_ACHIEVED", {
                value: overallStandings?.longGoal?.current
                  ? Math.round(
                      (overallStandings?.longGoal?.current /
                        overallStandings?.longGoal?.goal) *
                        100
                    )
                  : 0,
              })}
            </Text>
            <Text
              textAlign={"right"}
              fontSize={10}
              fontWeight={"bold"}
              fontColor={"#000000"}
            >
              {overallStandings?.longGoal?.goal
                .toString()
                .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}{" "}
              {overallStandings?.metricName || t("Kilometers")}
            </Text>
          </CardContainerHeader>
          <HorizontalRule />
          <VerticalContainer justifyContent={"center"}>
            <HorizontalContainer
              height={70}
              justifyContent={"space-around"}
              alignItems={"center"}
            >
              <Icon
                path={mdiChartLine}
                size={1.1}
                horizontal
                vertical
                rotate={180}
                color="black"
                style={{ width: "40px", height: "40px", marginRight: "8px" }}
              />
              <HorizontalTextContainer>
                <Text
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={20}
                  fontWeight={"bold"}
                  fontColor={"#000000"}
                >
                  {Math.round(overallStandings?.current || 0)
                    .toString()
                    .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
                </Text>
                <Text
                  paddingLeft={2}
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={12}
                  fontWeight={"normal"}
                  fontColor={"#000000"}
                >
                  {" "}
                  {overallStandings?.metricName || t("Kilometers")}
                </Text>
              </HorizontalTextContainer>
            </HorizontalContainer>
          </VerticalContainer>
          <HorizontalRule />
          <HorizontalContainer
            height={70}
            justifyContent={"center"}
            alignItems={"center"}
          >
            <VerticalContainer
              justifyContent={"center"}
              alignItems={"center"}
              height={60}
              flex={1}
              borderRight={"1px"}
            >
              <Text
                align={"center"}
                fontSize={12}
                fontWeight={"normal"}
                fontColor={SCHENKER_GREEN}
              >
                {t("YOUR_CONTRIBUTION")}
              </Text>
              <HorizontalTextContainer>
                <Text
                  align={"center"}
                  alignSelf={"flex-end"}
                  fontSize={20}
                  fontWeight={"bold"}
                  fontColor={SCHENKER_GREEN}
                >
                  {ownContribution?.value
                    ? Math.round(ownContribution.value)
                    : 0}
                </Text>
                <Text
                  paddingLeft={2}
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={12}
                  fontWeight={"normal"}
                  fontColor={SCHENKER_GREEN}
                >
                  {" "}
                  {overallStandings?.metricName || t("Kilometers")}
                </Text>
              </HorizontalTextContainer>
            </VerticalContainer>
            <VerticalRule />
            <VerticalContainer
              justifyContent={"center"}
              alignItems={"center"}
              height={60}
              flex={1}
            >
              <Text
                align={"center"}
                fontSize={12}
                fontWeight={"normal"}
                fontColor={SCHENKER_GREEN}
              >
                {t("YOUR_TEAMS_CONTRIBUTION")}
              </Text>
              <HorizontalTextContainer>
                <Text
                  align={"center"}
                  alignSelf={"flex-end"}
                  fontSize={20}
                  fontWeight={"bold"}
                  fontColor={SCHENKER_GREEN}
                >
                  {Math.round(ownContribution?.teamValue || 0)}
                </Text>
                <Text
                  paddingLeft={2}
                  alignSelf={"flex-end"}
                  align={"center"}
                  fontSize={12}
                  fontWeight={"normal"}
                  fontColor={SCHENKER_GREEN}
                >
                  {" "}
                  {overallStandings?.metricName || t("Kilometers")}
                </Text>
              </HorizontalTextContainer>
            </VerticalContainer>
          </HorizontalContainer>
          <HorizontalRule />
          <HorizontalContainer height={70} alignItems={"center"}>
            <Icon
              path={mdiAccountMultiple}
              size={1.1}
              horizontal
              vertical
              rotate={180}
              color="black"
              style={{
                width: "30px",
                height: "30px",
                marginRight: "8px",
                paddingTop: 4,
              }}
            />
            <HorizontalTextContainer>
              <Text
                textAlign={"left"}
                alignSelf={"flex-end"}
                align={"center"}
                fontSize={20}
                fontWeight={"bold"}
                fontColor={"#000000"}
              >
                {overallStandings?.teamCount}
              </Text>
              <Text
                paddingLeft={2}
                alignSelf={"flex-end"}
                fontSize={12}
                fontWeight={"normal"}
                fontColor={"#000000"}
              >
                {" "}
                {t("TEAMS")}
              </Text>
            </HorizontalTextContainer>
          </HorizontalContainer>
        </CardContainer>
        <CardContainer
          style={{
            justifyContent: "center",
            alignItems: "center",
            minHeight: 200,
          }}
        >
          <HorizontalContainer justifyContent={"center"} alignItems={"center"}>
            <VerticalContainer
              justifyContent={"center"}
              maxHeight={160}
              maxWidth={160}
              marginRight={30}
            >
              <CircularProgressbarWithChildren
                strokeWidth={7}
                styles={buildStyles({
                  pathColor: SCHENKER_GREEN,
                  trailColor: "#e9e9e9",
                  strokeLinecap: "butt",
                })}
                value={
                  overallStandings?.quarterlyGoal
                    ? (overallStandings.quarterlyGoal.current /
                        overallStandings.quarterlyGoal.goal) *
                      100
                    : 0
                }
              >
                <RelativeContainer height={90} width={90}>
                  <CircularProgressbarWithChildren
                    strokeWidth={7}
                    styles={buildStyles({
                      pathColor: "#aaaaaa",
                      trailColor: "#e9e9e9",
                      strokeLinecap: "butt",
                    })}
                    value={quarterTimePassed}
                  >
                    <Text
                      align={"center"}
                      fontSize={14}
                      fontWeight={"normal"}
                      fontColor={SCHENKER_GREEN}
                      paddingBottom={4}
                    >
                      {t("DONE")}
                    </Text>
                    <Text
                      align={"center"}
                      fontSize={30}
                      fontWeight={"bold"}
                      fontColor={SCHENKER_GREEN}
                      paddingBottom={6}
                    >
                      {Math.round(
                        (overallStandings?.quarterlyGoal
                          ? overallStandings.quarterlyGoal.current /
                            overallStandings.quarterlyGoal.goal
                          : 0) * 100
                      )}
                      %
                    </Text>
                    <Text
                      align={"center"}
                      fontSize={10}
                      fontWeight={"normal"}
                      fontColor={"#000000"}
                    >
                      {t("TIME_LEFT")}
                    </Text>
                    <Text
                      align={"center"}
                      fontSize={12}
                      fontWeight={"normal"}
                      fontColor={"#000000"}
                    >
                      {100 - quarterTimePassed}%
                    </Text>
                  </CircularProgressbarWithChildren>
                </RelativeContainer>
              </CircularProgressbarWithChildren>
            </VerticalContainer>
            <VerticalContainer minWidth={60} paddingLeft={4}>
              <VerticalContainer
                justifyContent={"flex-start"}
                alignItems={"flex-end"}
                marginRight={8}
              ></VerticalContainer>
              <VerticalContainer alignItems={"flex-start"}>
                <Text fontSize={12} fontWeight={"normal"} fontColor={"#000000"}>
                  {t("OUR_QUARTERLY_GOAL")}
                </Text>
                <HorizontalTextContainer>
                  <Text fontSize={20} fontWeight={"bold"} fontColor={"#000000"}>
                    {overallStandings?.quarterlyGoal?.goal
                      ? overallStandings?.quarterlyGoal?.goal
                          .toString()
                          .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")
                      : 0}
                  </Text>
                  <Text
                    paddingLeft={4}
                    alignSelf={"flex-end"}
                    paddingBottom={6}
                    fontSize={12}
                    fontWeight={"normal"}
                    fontColor={"#000000"}
                  >
                    {" "}
                    {overallStandings?.metricName || t("Kilometers")}
                  </Text>
                </HorizontalTextContainer>
              </VerticalContainer>
              <VerticalContainer marginTop={12} alignItems={"flex-start"}>
                <Text fontSize={12} fontWeight={"normal"} fontColor={"#000000"}>
                  {t("CURRENT_RESULT")}
                </Text>
                <HorizontalTextContainer>
                  <Text fontSize={20} fontWeight={"bold"} fontColor={"#000000"}>
                    {Math.round(
                      overallStandings?.quarterlyGoal?.current
                        ? overallStandings?.quarterlyGoal?.current
                        : 0
                    )
                      .toString()
                      .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")}
                  </Text>
                  <Text
                    paddingLeft={4}
                    alignSelf={"flex-end"}
                    fontSize={12}
                    fontWeight={"normal"}
                    fontColor={"#000000"}
                  >
                    {" "}
                    {overallStandings?.metricName || t("Kilometers")}
                  </Text>
                </HorizontalTextContainer>
              </VerticalContainer>
            </VerticalContainer>
          </HorizontalContainer>
        </CardContainer>
        <CardContainer style={{ minHeight: 200, justifyContent: "center" }}>
          <VerticalContainer justifyContent={"space-evenly"} minHeight={130}>
            <Text
              align={"center"}
              fontSize={12}
              fontWeight={"bold"}
              fontColor={"#000000"}
            >
              {overallStandings?.donation?.totalContributionText ||
                t("OUR_CURRENT_DONATION")}
            </Text>
            <Text
              align={"center"}
              fontSize={20}
              fontWeight={"bold"}
              fontColor={SCHENKER_GREEN}
            >
              {overallStandings?.donation?.current
                ? Math.round(Number(overallStandings?.donation?.current))
                    .toString()
                    .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")
                : 0}{" "}
              {overallStandings?.donation?.unitName}
            </Text>
            <HorizontalRule />
            <Text
              align={"center"}
              fontSize={12}
              fontWeight={"bold"}
              fontColor={"#000000"}
            >
              {overallStandings?.donation?.goalText ||
                t("OUR_QUARTERLY_DONATION_GOAL")}
            </Text>
            <Text
              align={"center"}
              fontSize={20}
              fontWeight={"bold"}
              fontColor={SCHENKER_GREEN}
            >
              {overallStandings?.donation?.goal
                ? Math.round(Number(overallStandings?.donation?.goal))
                    .toString()
                    .replace(/(\d)(?=(\d{3})+$)/g, "$1 ")
                : 0}{" "}
              {overallStandings?.donation?.unitName}
            </Text>
          </VerticalContainer>
        </CardContainer>
      </VerticalGridContainer>
      <VerticalGridContainer>
        <TableContainer>
          <TitleContainer>
            <Text fontSize={18} fontColor={"#000000"} fontWeight={"bold"}>
              {t("GOAL_ACHIEVEMENT")}
            </Text>
            <Text fontSize={18} fontColor={SCHENKER_GREEN} fontWeight={"bold"}>
              {t("REGIONS")}
            </Text>
            <MonthlyQuarterlyContainer>
              <AbsoluteTextLink
                onClick={() => {
                  setRegionsRankingPeriod(SharedConstants.MONTHLY);
                }}
                fontSize={12}
                fontColor={
                  regionsRankingPeriod === SharedConstants.MONTHLY
                    ? SCHENKER_GREEN
                    : "#000000"
                }
                fontWeight={
                  regionsRankingPeriod === SharedConstants.MONTHLY
                    ? "bold"
                    : "normal"
                }
              >
                {t("MONTHLY")}
              </AbsoluteTextLink>
              <AbsoluteText
                fontSize={12}
                fontColor={SCHENKER_GREEN}
                fontWeight={"normal"}
              >
                /
              </AbsoluteText>
              <AbsoluteTextLink
                onClick={() => {
                  setRegionsRankingPeriod(SharedConstants.QUARTERLY);
                }}
                fontSize={12}
                fontColor={
                  regionsRankingPeriod === SharedConstants.MONTHLY
                    ? "#000000"
                    : SCHENKER_GREEN
                }
                fontWeight={
                  regionsRankingPeriod === SharedConstants.MONTHLY
                    ? "normal"
                    : "bold"
                }
              >
                {t("QUARTERLY")}
              </AbsoluteTextLink>
            </MonthlyQuarterlyContainer>
          </TitleContainer>
          {regionsRankingPeriod === SharedConstants.MONTHLY
            ? regionsRankingListItemsMonthly
            : regionsRankingListItemsQuarterly}
          {showAllRegions
            ? regionsRankingPeriod === SharedConstants.MONTHLY
              ? regionsAfterTenRankingListItemsMonthly
              : regionsAfterTenRankingListItemsQuarterly
            : null}
          {showAllRegions ? (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllRegions(false);
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_LESS")} ↑
              </Text>
            </RankingListItem>
          ) : null}
        </TableContainer>
      </VerticalGridContainer>
      <VerticalGridContainer>
        <TableContainer>
          <TitleContainer>
            <Text fontSize={18} fontColor={"#000000"} fontWeight={"bold"}>
              {t("GOAL_ACHIEVEMENT")}
            </Text>
            <Text fontSize={18} fontColor={SCHENKER_GREEN} fontWeight={"bold"}>
              {t("COUNTRIES") + t("COUNTRIES_SIZE_SMALL")}
            </Text>
            <MonthlyQuarterlyContainer>
              <AbsoluteTextLink
                onClick={() => {
                  setSmallCountriesRankingPeriod(SharedConstants.MONTHLY);
                }}
                fontSize={12}
                fontColor={
                  smallCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? SCHENKER_GREEN
                    : "#000000"
                }
                fontWeight={
                  smallCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "bold"
                    : "normal"
                }
              >
                {t("MONTHLY")}
              </AbsoluteTextLink>
              <AbsoluteText
                fontSize={12}
                fontColor={SCHENKER_GREEN}
                fontWeight={"normal"}
              >
                /
              </AbsoluteText>
              <AbsoluteTextLink
                onClick={() => {
                  setSmallCountriesRankingPeriod(SharedConstants.QUARTERLY);
                }}
                fontSize={12}
                fontColor={
                  smallCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "#000000"
                    : SCHENKER_GREEN
                }
                fontWeight={
                  smallCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "normal"
                    : "bold"
                }
              >
                {t("QUARTERLY")}
              </AbsoluteTextLink>
            </MonthlyQuarterlyContainer>
          </TitleContainer>
          {smallCountriesRankingPeriod === SharedConstants.MONTHLY
            ? smallCountriesRankingListItemsMonthly
            : smallCountriesRankingListItemsQuarterly}
          {showAllSmallCountries
            ? smallCountriesRankingPeriod === SharedConstants.MONTHLY
              ? smallCountriesAfterTenRankingListItemsMonthly
              : smallCountriesAfterTenRankingListItemsQuarterly
            : null}
          {showAllSmallCountries ? (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllSmallCountries(false);
                let doc: HTMLElement | null =
                  document.getElementById("countries");
                if (doc) {
                  doc.style.marginBottom = "0px";
                }
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_LESS")} ↑
              </Text>
            </RankingListItem>
          ) : null}
          {showAllSmallCountries ? null : (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllSmallCountries(true);
                let doc: HTMLElement | null =
                  document.getElementById("countries");
                if (doc) {
                  doc.style.marginBottom =
                    (smallCountriesRankingListItemsMonthly.length +
                      smallCountriesAfterTenRankingListItemsMonthly.length) *
                      22 +
                    "px";
                }
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_ALL")} ↓
              </Text>
            </RankingListItem>
          )}
        </TableContainer>
      </VerticalGridContainer>
      <VerticalGridContainer>
        <TableContainer>
          <TitleContainer>
            <Text fontSize={18} fontColor={"#000000"} fontWeight={"bold"}>
              {t("GOAL_ACHIEVEMENT")}
            </Text>
            <Text fontSize={18} fontColor={SCHENKER_GREEN} fontWeight={"bold"}>
              {t("COUNTRIES") + t("COUNTRIES_SIZE_MEDIUM")}
            </Text>
            <MonthlyQuarterlyContainer>
              <AbsoluteTextLink
                onClick={() => {
                  setMediumCountriesRankingPeriod(SharedConstants.MONTHLY);
                }}
                fontSize={12}
                fontColor={
                  mediumCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? SCHENKER_GREEN
                    : "#000000"
                }
                fontWeight={
                  mediumCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "bold"
                    : "normal"
                }
              >
                {t("MONTHLY")}
              </AbsoluteTextLink>
              <AbsoluteText
                fontSize={12}
                fontColor={SCHENKER_GREEN}
                fontWeight={"normal"}
              >
                /
              </AbsoluteText>
              <AbsoluteTextLink
                onClick={() => {
                  setMediumCountriesRankingPeriod(SharedConstants.QUARTERLY);
                }}
                fontSize={12}
                fontColor={
                  mediumCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "#000000"
                    : SCHENKER_GREEN
                }
                fontWeight={
                  mediumCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "normal"
                    : "bold"
                }
              >
                {t("QUARTERLY")}
              </AbsoluteTextLink>
            </MonthlyQuarterlyContainer>
          </TitleContainer>
          {mediumCountriesRankingPeriod === SharedConstants.MONTHLY
            ? mediumCountriesRankingListItemsMonthly
            : mediumCountriesRankingListItemsQuarterly}
          {showAllMediumCountries
            ? mediumCountriesRankingPeriod === SharedConstants.MONTHLY
              ? mediumCountriesAfterTenRankingListItemsMonthly
              : mediumCountriesAfterTenRankingListItemsQuarterly
            : null}
          {showAllMediumCountries ? (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllMediumCountries(false);
                let doc: HTMLElement | null =
                  document.getElementById("countries");
                if (doc) {
                  doc.style.marginBottom = "0px";
                }
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_LESS")} ↑
              </Text>
            </RankingListItem>
          ) : null}
          {showAllMediumCountries ? null : (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllMediumCountries(true);
                let doc: HTMLElement | null =
                  document.getElementById("countries");
                if (doc) {
                  doc.style.marginBottom =
                    (mediumCountriesRankingListItemsMonthly.length +
                      mediumCountriesAfterTenRankingListItemsMonthly.length) *
                      22 +
                    "px";
                }
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_ALL")} ↓
              </Text>
            </RankingListItem>
          )}
        </TableContainer>
      </VerticalGridContainer>
      <VerticalGridContainer>
        <TableContainer>
          <TitleContainer>
            <Text fontSize={18} fontColor={"#000000"} fontWeight={"bold"}>
              {t("GOAL_ACHIEVEMENT")}
            </Text>
            <Text fontSize={18} fontColor={SCHENKER_GREEN} fontWeight={"bold"}>
              {t("COUNTRIES") + t("COUNTRIES_SIZE_LARGE")}
            </Text>
            <MonthlyQuarterlyContainer>
              <AbsoluteTextLink
                onClick={() => {
                  setLargeCountriesRankingPeriod(SharedConstants.MONTHLY);
                }}
                fontSize={12}
                fontColor={
                  largeCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? SCHENKER_GREEN
                    : "#000000"
                }
                fontWeight={
                  largeCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "bold"
                    : "normal"
                }
              >
                {t("MONTHLY")}
              </AbsoluteTextLink>
              <AbsoluteText
                fontSize={12}
                fontColor={SCHENKER_GREEN}
                fontWeight={"normal"}
              >
                /
              </AbsoluteText>
              <AbsoluteTextLink
                onClick={() => {
                  setLargeCountriesRankingPeriod(SharedConstants.QUARTERLY);
                }}
                fontSize={12}
                fontColor={
                  largeCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "#000000"
                    : SCHENKER_GREEN
                }
                fontWeight={
                  largeCountriesRankingPeriod === SharedConstants.MONTHLY
                    ? "normal"
                    : "bold"
                }
              >
                {t("QUARTERLY")}
              </AbsoluteTextLink>
            </MonthlyQuarterlyContainer>
          </TitleContainer>
          {largeCountriesRankingPeriod === SharedConstants.MONTHLY
            ? largeCountriesRankingListItemsMonthly
            : largeCountriesRankingListItemsQuarterly}
          {showAllLargeCountries
            ? largeCountriesRankingPeriod === SharedConstants.MONTHLY
              ? largeCountriesAfterTenRankingListItemsMonthly
              : largeCountriesAfterTenRankingListItemsQuarterly
            : null}
          {showAllLargeCountries ? (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllLargeCountries(false);
                let doc: HTMLElement | null =
                  document.getElementById("countries");
                if (doc) {
                  doc.style.marginBottom = "0px";
                }
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_LESS")} ↑
              </Text>
            </RankingListItem>
          ) : null}
          {showAllLargeCountries ? null : (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllLargeCountries(true);
                let doc: HTMLElement | null =
                  document.getElementById("countries");
                if (doc) {
                  doc.style.marginBottom =
                    (largeCountriesRankingListItemsMonthly.length +
                      largeCountriesAfterTenRankingListItemsMonthly.length) *
                      22 +
                    "px";
                }
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_ALL")} ↓
              </Text>
            </RankingListItem>
          )}
        </TableContainer>
      </VerticalGridContainer>
      <VerticalGridContainer>
        <TableContainer>
          <TitleContainer>
            <Text fontSize={18} fontColor={"#000000"} fontWeight={"bold"}>
              {t("GOAL_ACHIEVEMENT")}
            </Text>
            <Text fontSize={18} fontColor={SCHENKER_GREEN} fontWeight={"bold"}>
              {t("TEAMS")}
            </Text>
            <MonthlyQuarterlyContainer>
              <AbsoluteTextLink
                onClick={() => {
                  setTeamsRankingPeriod(SharedConstants.MONTHLY);
                }}
                fontSize={12}
                fontColor={
                  teamsRankingPeriod === SharedConstants.MONTHLY
                    ? SCHENKER_GREEN
                    : "#000000"
                }
                fontWeight={
                  teamsRankingPeriod === SharedConstants.MONTHLY
                    ? "bold"
                    : "normal"
                }
              >
                {t("MONTHLY")}
              </AbsoluteTextLink>
              <AbsoluteText
                fontSize={12}
                fontColor={SCHENKER_GREEN}
                fontWeight={"normal"}
              >
                /
              </AbsoluteText>
              <AbsoluteTextLink
                onClick={() => {
                  setTeamsRankingPeriod(SharedConstants.QUARTERLY);
                }}
                fontSize={12}
                fontColor={
                  teamsRankingPeriod === SharedConstants.MONTHLY
                    ? "#000000"
                    : SCHENKER_GREEN
                }
                fontWeight={
                  teamsRankingPeriod === SharedConstants.MONTHLY
                    ? "normal"
                    : "bold"
                }
              >
                {t("QUARTERLY")}
              </AbsoluteTextLink>
            </MonthlyQuarterlyContainer>
          </TitleContainer>
          {teamsRankingPeriod === SharedConstants.MONTHLY
            ? teamsRankingListItemsMonthly
            : teamsRankingListItemsQuarterly}
          {showAllTeams
            ? teamsRankingPeriod === SharedConstants.MONTHLY
              ? teamsAfterTenRankingListItemsMonthly
              : teamsAfterTenRankingListItemsQuarterly
            : teamsRankingPeriod === SharedConstants.MONTHLY
            ? ownTeamListItemMonthly
            : ownTeamListItemQuarterly}
          {showAllTeams ? (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllTeams(false);
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("SEE_LESS")} ↑
              </Text>
            </RankingListItem>
          ) : null}
          {showAllTeams ? null : (
            <RankingListItem
              justifyContent={"flex-end"}
              padding={8}
              onClick={() => {
                setShowAllTeams(true);
              }}
            >
              <Text
                fontSize={14}
                fontWeight={"bold"}
                fontColor={SCHENKER_GREEN}
              >
                {t("MORE")} ↓
              </Text>
            </RankingListItem>
          )}
        </TableContainer>
      </VerticalGridContainer>
    </Container>
  );
};

type TextContainerProps = {
  width: number;
  backgroundColor?: string;
  textAlign?: string;
};

type ContainerProps = {
  height?: number;
  width?: number;
};

type RelativeContainerProps = {
  height?: number;
  width?: number;
};

type CardContainerProps = {};

type TableContainerProps = {};

type CardContainerHeaderProgressProps = {
  width: number;
};

type HorizontalContainerProps = {
  height?: number;
  width?: number;
  justifyContent?: string;
  alignItems?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  marginTop?: number;
};

type VerticalContainerProps = {
  height?: number;
  width?: number;
  minHeight?: number;
  maxHeight?: number;
  maxWidth?: number;
  flex?: number;
  minWidth?: number;
  justifyContent?: string;
  alignItems?: string;
  borderRight?: string;
  backgroundColor?: string;
  paddingLeft?: number;
  margin?: number;
  marginRight?: number;
  marginTop?: number;
  boxShadow?: boolean;
};

type HorizontalRuleProps = {
  width?: number;
};

type TextProps = {
  fontSize?: number;
  fontColor?: string;
  fontWeight?: string;
  lineHeight?: number;
  align?: string;
  backgroundColor?: string;
  alignSelf?: string;
  textAlign?: string;
  paddingBottom?: number;
  paddingLeft?: number;
  paddingRight?: number;
};

type VerticalGridContainerProps = {
  backgroundColor?: string;
};

type BackgroundHeaderProps = {
  gridArea: string;
};

type RankingListItemProps = {
  padding?: number;
  justifyContent?: string;
  backgroundColor?: string;
};

const Container = styled.div<ContainerProps>`
  display: flex;
  flex-direction: column;
  background-color: #f8f8f8;
`;

const BackgroundHeader = styled.div<BackgroundHeaderProps>`
  background-color: ${SCHENKER_GREEN};
  min-height: 130px;
  width: 100%;
  display: flex;
  background-image: url(${logo});
  background-size: 200px;
  background-repeat: no-repeat;
  background-position-y: 20px;
  background-position-x: 8px;
`;

const RelativeContainer = styled.div<RelativeContainerProps>`
  position: relative;
  margin: 8px;
  width: ${(props) => (props.width ? props.width : "10")}%;
  height: ${(props) => (props.height ? props.height : "10")}%;
  justify-content: center;
  align-items: center;
`;

const CardContainer = styled.div<CardContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  background-color: #ffffff;
  margin-top: 16px;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
  -webkit-box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.26);
  box-shadow: 1px 3px 15px -2px rgba(0, 0, 0, 0.26);
`;

const TableContainer = styled.div<TableContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  min-width: 250px;
  width: 100%;
  max-width: 600px;
  overflow: hidden;
  background-color: #ffffff;
  margin-left: 16px;
  margin-right: 16px;
  border: 1px solid #eeeeee;
  border-radius: 15px;
`;

const CardContainerHeader = styled.div`
  height: 24px;
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding-left: 8px;
  padding-right: 8px;
  border-radius: 15px 15px 0px 0px;
`;

const CardContainerHeaderProgress = styled.div<CardContainerHeaderProgressProps>`
  height: 24px;
  position: absolute;
  z-index: 0;
  top: 0;
  left: 0;
  width: ${(props) => props.width}%;
  border-radius: 15px 15px 0px 0px;
  background-color: #eeeeee;
`;

const HorizontalTextContainer = styled.div`
  display: inline;
  padding-top: 5px;
`;

const Text = styled.span<TextProps>`
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  line-height: ${(props) => props.lineHeight};
  margin: 0px;
  text-align: ${(props) => props.align};
  background-color: ${(props) => props.backgroundColor};
  align-self: ${(props) => props.alignSelf};
  text-align: ${(props) => props.textAlign};
  padding-bottom: ${(props) => props.paddingBottom}px;
  padding-left: ${(props) => props.paddingLeft}px;
  padding-right: ${(props) => props.paddingRight}px;
  z-index: 2;
  font-family: "Ubuntu";
`;

const AbsoluteText = styled.p<TextProps>`
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  font-family: "Ubuntu";
`;

const AbsoluteTextLink = styled.p<TextProps>`
  color: ${(props) => props.fontColor};
  font-weight: ${(props) => props.fontWeight};
  font-size: ${(props) => props.fontSize}px;
  font-family: "Ubuntu";
  cursor: pointer;
`;

const HorizontalContainer = styled.div<HorizontalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor};
  padding-left: ${(props) => props.paddingLeft || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  ${(props) => (props.height ? "height: " + props.height + "px;" : "")}
`;

const VerticalContainer = styled.div<VerticalContainerProps>`
  position: relative;
  display: flex;
  flex-direction: column;
  flex: ${(props) => (props.flex ? props.flex : 1)};
  justify-content: ${(props) => props.justifyContent};
  align-items: ${(props) => props.alignItems};
  background-color: ${(props) => props.backgroundColor};
  margin-left: ${(props) => props.paddingLeft || 0}px;
  margin-right: ${(props) => props.marginRight || 0}px;
  margin-top: ${(props) => props.marginTop || 0}px;
  margin: ${(props) => props.margin}px;
  ${(props) =>
    props.minHeight ? "min-height: " + props.minHeight + "px;" : ""}
  ${(props) =>
    props.maxHeight ? "max-height: " + props.maxHeight + "px;" : ""}
  ${(props) => (props.maxWidth ? "max-width: " + props.maxWidth + "px;" : "")}
  ${(props) => (props.height ? "height: " + props.height + "px;" : "")}
  ${(props) => (props.width ? "width: " + props.width + "px;" : "")}
`;

const VerticalGridContainer = styled.div<VerticalGridContainerProps>`
  background-color: ${(props) =>
    props.backgroundColor ? props.backgroundColor : ""};
  display: flex;
  align-items: center;
  flex-direction: column;
  padding: 8px;
`;

const HorizontalRule = styled.hr<HorizontalRuleProps>`
  margin-top: 0;
  margin-bottom: 0;
  border: 0;
  border-color: #f0f0f0;
  color: #f0f0f0;
  background-color: #f0f0f0;
  width: ${(props) => (props.width ? props.width + "%" : "100%")};
  height: 1px;
`;

const VerticalRule = styled.div`
  background-color: #f0f0f0;
  height: 100%;
  width: 1px;
`;

const TitleContainer = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #dbdbdb;
  min-height: 10%;
  width: 100%;
  border-radius: 15px 15px 0px 0px;
  padding: 8px;
  box-sizing: border-box;
`;

const MonthlyQuarterlyContainer = styled.div`
  position: absolute;
  display: flex;
  flex-direction: row;
  padding-right: 16px;
  z-index: 5;
  bottom: 0;
  right: 0;
`;

const RankingListItem = styled.div<RankingListItemProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  padding: ${(props) => props.padding}px;
  justify-content: ${(props) => props.justifyContent};
  width: 100%;
  background-color: ${(props) => props.backgroundColor || "white"};
`;

const TextContainer = styled.p<TextContainerProps>`
  width: ${(props) => props.width}%;
  background-color: ${(props) => props.backgroundColor};
  text-align: ${(props) => (props.textAlign ? props.textAlign : "center")};
  padding-right: 8px;
`;

export default Microsite;
